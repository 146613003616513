export const environment: any = {
    name: 'staging',
    production: true,
    apiUrl: 'https://consumer-api.clickdealer.guru',
    assetsUrl: 'https://stage-click-dealer-consumer-assets.s3.eu-west-2.amazonaws.com',
    subDomain: window.location.host.split('.')[0],
    domain: 'consumer.clickdealer.guru',

    gtmContainerId: 'GTM-WC3R83P',
    gtmEnvironmentId: '58',
    gtmEnvironmentAuth: '7g_yr4jotQpd7kQXrHo92w',
};
